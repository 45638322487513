import React from 'react'
import './Button.scss'

function Button({ styles, children, handleClick}) {
    return (
        <div onClick={handleClick} className={`containerButton ${styles}`}>
            {children}
        </div>
    )
}

export default Button