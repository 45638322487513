import React from 'react'
import {useTranslation} from "react-i18next";
import Button from "../../components/Buttons/Button/Button";
import {useNavigate} from "react-router-dom";
import routes from "../../utils/routes.json";

export default function NotFound() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    function goHome() {
        navigate(routes.home);
    }

    return (
        <div className="containerPage d-flex justify-center flex-column align-center h-30 mt-5">
            <h1>{t('notFound.title')}</h1>
            <p>{t('notFound.message')}</p>
            <Button handleClick={goHome} styles="containerButton button black mt-5 pl-2 pr-2">
                {t('notFound.back')}
            </Button>
        </div>
    )
}
