import React from 'react'
import "./Legal.scss"
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import {Divider} from "@mui/material";


export default function Legal() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const owners = t('legal.property.part1', { returnObjects: true });
    const rights = t('legal.rights.part2', { returnObjects: true });

    return (
        <div className='containerPage'>

            <div className='legalPage'>
                <h1 className="mt-3 mb-3 d-flex justify-center">{t('legal.title')}</h1>

                <Divider/>

                <h2 className="mt-3">{t('legal.subTitle1')}</h2>

                <p className="font-bold subTitle">{t('legal.property.title')}</p>
                <ul>
                    {owners.map((owner, index) => (
                        <li key={index}>{owner}</li>
                    ))}
                </ul>
                <p className="mt-0">
                    {t('legal.property.part2')}
                    <a href={`mailto:${t('legal.contactEmail')}`}>{t('legal.contactEmail')}</a>
                </p>

                <p className="font-bold subTitle">{t('legal.host.title')}</p>
                <a href="https://www.hostinger.com" target="_blank" rel="noopener noreferrer">{t('legal.host.part1')}</a>

                <p className="font-bold subTitle">{t('legal.object.title')}</p>
                <p className="mt-0">{t('legal.object.part1')}</p>

                <p className="font-bold subTitle">{t('legal.responsibility.title')}</p>
                <p className="mt-0 mb-3">{t('legal.responsibility.part1')}</p>

                <Divider/>

                <h2 className="mt-3">{t('legal.subTitle2')}</h2>

                <p className="font-bold subTitle">{t('legal.rgpdIntro.title')}</p>
                <p className="mt-0">{t('legal.rgpdIntro.part1')}</p>

                <p className="font-bold subTitle">{t('legal.gathering.title')}</p>
                <p className="mt-0">{t('legal.gathering.part1')}</p>
                <ul>
                    <li>{t('legal.gathering.userName')}</li>
                    <li>{t('legal.gathering.password')}</li>
                    <li>{t('legal.gathering.email')}</li>
                </ul>
                <p className="mt-0">{t('legal.gathering.part2')}</p>

                <p className="font-bold subTitle">{t('legal.use.title')}</p>
                <p className="mt-0">{t('legal.use.part1')}</p>
                <ul>
                    <li>{t('legal.use.part2')}</li>
                    <li>{t('legal.use.part3')}</li>
                </ul>

                <p className="font-bold subTitle">{t('legal.dataSharing.title')}</p>
                <p className="mt-0">{t('legal.dataSharing.part1')}</p>

                <p className="font-bold subTitle">{t('legal.security.title')}</p>
                <p className="mt-0">{t('legal.security.part1')}</p>

                <p className="font-bold subTitle">{t('legal.rights.title')}</p>
                <p className="mt-0">{t('legal.rights.part1')}</p>
                <ul>
                    {rights.map((right, index) => (
                        <li key={index}>{right}</li>
                    ))}
                </ul>
                <p className="mt-0">
                    {t('legal.rights.part3')}
                    <a href={`mailto:${t('legal.contactEmail')}`}>{t('legal.contactEmail')}</a>
                </p>

                <p className="font-bold subTitle">{t('legal.update.title')}</p>
                <p className="mt-0">{t('legal.update.part1')}</p>

                <p className="font-bold subTitle">{t('legal.contact.title')}</p>
                <p className="mt-0">
                    {t('legal.contact.part1')}
                    <a href={`mailto:${t('legal.contactEmail')}`}>{t('legal.contactEmail')}</a>
                </p>

                <p className='mt-6 d-flex justify-end'>{t('legal.date')}</p>

            </div>
        </div>

    )

}

