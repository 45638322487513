import React, {useEffect, useRef, useState} from 'react';
import {Line} from "react-chartjs-2";
import "./LineChart.scss";

const LineChart = ({ data, labels, className, lineColor }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const maxPixelsPerLabel = 75;
    const maxLabelsToShow = Math.floor(dimensions.width / maxPixelsPerLabel);
    const skipInterval = Math.ceil(labels.length / maxLabelsToShow);

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                borderColor: lineColor,
                spanGaps: true,
                pointRadius: 0,
                pointHitRadius: 10,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (val, index) {
                        return index % skipInterval === 0 ? this.getLabelForValue(val) : '';
                    },
                    maxRotation: 0,
                    autoSkip: false,
                },
            },
            y: {
                position: 'right',
                grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                },
                ticks: {}
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        animation: {
            duration: 0,
        },
    };

    return (
        <div ref={containerRef} className={className}>
            <Line ref={chartRef} data={chartData} options={options}/>
        </div>
    );
};

export default LineChart;