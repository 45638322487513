import React, {useEffect, useState} from 'react';
import Spinner from "../../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import {RequestAuth} from "../../../request/RequestAuth";
import {useNotification} from "../../../containers/NotificationContext/NotificationContext";
import {useNavigate} from "react-router-dom";
import routes from "../../../utils/routes.json";
import PasswordChange from "../../../components/Forms/PasswordChange/PasswordChange";
import "./EmailAction.scss";

function EmailAction() {
    const {t} = useTranslation();
    const authService = new RequestAuth();
    const notificationService = useNotification();
    const router = useNavigate();

    const [action, setAction] = useState("");
    const [code, setCode] = useState("");

    const [displayEmailAction, setDisplayEmailAction] = useState(false);
    const [displayPasswordAction, setDisplayPasswordAction] = useState(false);

    function getUrlParams() {
        const urlParams = new URLSearchParams(window.location.search);
        setAction(urlParams.get('action'));
        setCode(urlParams.get('code'));
    }

    async function verifyEmail() {
        try {
            await authService.verifyEmail(code);
            notificationService.notify(t('verifyEmail.success'), 'success');
            router(routes.auth, {state: routes.login});

        } catch (error) {
            if (error.response.status === 410) {
                notificationService.notify(t('verifyEmail.expired'), 'error');
            } else {
                notificationService.notify(t('verifyEmail.error'), 'error');
            }
            router(routes.home);
        }
    }

    async function resetPassword(password) {
        try {
            await authService.forgotPasswordVerifyCode(code, password);
            notificationService.notify(t('passwordAction.success'), 'success');
            router(routes.auth, {state: routes.login});

        } catch (error) {
            if (error.response.status === 410) {
                notificationService.notify(t('passwordAction.expired'), 'error');
            } else {
                notificationService.notify(t('passwordAction.error'), 'error');
            }
            router(routes.home);
        }
    }

    useEffect(() => {
        getUrlParams();
    }, []);

    useEffect(() => {
        if (action) {
            if (action === 'REGISTER') {
                setDisplayEmailAction(true);
                verifyEmail();

            } else if (action === 'FORGOT_PASSWORD') {
                setDisplayPasswordAction(true);

            } else {
                console.log('Erreur de récupération de l\'action');
                router(routes.home);
            }
        }
    }, [action]);

    return (
        <div>
            {displayEmailAction &&
                <div className="containerPage d-flex justify-center flex-column align-center h-30 mt-5">
                    <h1>{t('verifyEmail.message')}</h1>
                    <Spinner/>
                </div>
            }

            {displayPasswordAction &&
                <div className="containerPage d-flex justify-center flex-column align-center mt-10">
                    <PasswordChange onSubmit={resetPassword} className="password-container"/>
                </div>
            }
        </div>
    );
}

export default EmailAction;