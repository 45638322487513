import React, {useState} from "react";
import "./SwitchButton.scss";

function SwitchButton({leftLabel, rightLabel, onClickLeft, onClickRight, className}) {

    const [switchBtn, setSwitchBtn] = useState(true);

    function onLeftClicAction() {
        setSwitchBtn(true);
        onClickLeft();
    }

    function onRightClickAction() {
        setSwitchBtn(false);
        onClickRight();
    }

    return (
        <div className={`d-flex justify-center mb-1-r ${className}`}>
            <div className="switch-container">
                <div className={switchBtn ? "switch-btn active" : "switch-btn"}
                     onClick={onLeftClicAction}>
                    <p>{leftLabel}</p>
                </div>
                <div className={switchBtn ? "switch-btn" : "switch-btn active"}
                     onClick={onRightClickAction}>
                    <p>{rightLabel}</p>
                </div>
            </div>
        </div>
    );
}

export default SwitchButton;