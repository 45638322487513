import constants from "../utils/constants.js";
import Interceptor from "./Interceptor";

export class RequestAuth {
    static ENDPOINT = "/auth"
    axios = Interceptor.getInstance();

    async login(login, password) {
        try {
            return await this.axios.post(constants.url_api + RequestAuth.ENDPOINT + "/login",
                {
                    "login": login,
                    "password": password
                });
        } catch (error) {
            throw error;
        }
    }

    async register(username, email, password, language) {
        try {
            return await this.axios.post(constants.url_api + RequestAuth.ENDPOINT + "/register",
                {
                    "email": email,
                    "username": username,
                    "password": password,
                    "language": language
                });

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async changePassword(password) {
        try {
            return await this.axios.patch(constants.url_api + RequestAuth.ENDPOINT + "/updatePassword",
                {
                    "password": password
                });

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async deleteAccount() {
        try {
            return await this.axios.delete(constants.url_api + RequestAuth.ENDPOINT + "/deleteAccount");

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    async verifyEmail(code) {
        try {
            return await this.axios.post(constants.url_api + RequestAuth.ENDPOINT + "/verifyEmail", null, {
                params: {
                    code: code
                }
            });

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async forgotPasswordSendEmail(emailOrUsername) {
        try {
            return await this.axios.post(constants.url_api + RequestAuth.ENDPOINT + "/forgotPassword", null, {
                params: {
                    userIdentifier: emailOrUsername
                }
            });

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async forgotPasswordVerifyCode(code, newPassword) {
        try {
            return await this.axios.patch(constants.url_api + RequestAuth.ENDPOINT + "/forgotPassword",
                {
                    password: newPassword
                },
                {
                    params: {
                        code: code
                    }
                });

        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}