import React, {useState} from 'react';
import {TextField} from "@mui/material";
import Button from "../../components/Buttons/Button/Button";
import './ForgotPassword.scss';
import {useTranslation} from "react-i18next";
import {useNotification} from "../../containers/NotificationContext/NotificationContext";
import {RequestAuth} from "../../request/RequestAuth";
import {useNavigate} from "react-router-dom";
import routes from "../../utils/routes.json";

function ForgotPassword() {

    const {t} = useTranslation();
    const notificationService = useNotification();
    const authService = new RequestAuth();
    const router = useNavigate();

    const [emailOrUsername, setEmailOrUsername] = useState("");

    async function sendEmail() {
        if (emailOrUsername !== "") {
            try {
                await authService.forgotPasswordSendEmail(emailOrUsername);
                notificationService.notify(t('passwordAction.emailSent'), 'success');
                router(routes.auth, {state: routes.login});

            } catch (error) {
                notificationService.notify(t('passwordAction.error'), 'error');
            }
        } else {
            notificationService.notify(t('passwordAction.invalidEmail'), 'error');
        }
    }

    return (
        <div className="containerPage d-flex justify-center align-center h-100">
            <div className="forgot-password-container">
                <h1 className="mb-15">{t('passwordAction.message')}</h1>
                <TextField placeholder={t('passwordAction.emailPlaceholder')}
                           className="forgot-password-input"
                           value={emailOrUsername}
                           onChange={(e) => setEmailOrUsername(e.target.value)}
                           onKeyDown={e => e.key === 'Enter' && sendEmail()}
                />
                <Button children={t('passwordAction.button')} styles={"button black mt-5 forgot-password-input"} handleClick={sendEmail}/>
            </div>
        </div>
    );
}

export default ForgotPassword;