import React, {useEffect, useState} from 'react';
import InputLabel from "../../components/Input/InputLabel/InputLabel";
import "./StocksChat.scss";
import like from "../../assets/img/like.svg";
import bean from "../../assets/img/poubelle.png";
import edit from "../../assets/img/edit.png"
import Button from "../../components/Buttons/Button/Button";
import {RequestCommunity} from "../../request/RequestCommunity";
import send from "../../assets/img/send-message.png";
import utils from "../../utils/utils.json";
import {Avatar, Divider} from "@mui/material";
import {Auth} from "../../utils/Auth";
import {useTranslation} from "react-i18next";
import {useNotification} from "../NotificationContext/NotificationContext";

const StocksChat = ({stocks}) => {
  const auth = new Auth();
  const [commentaires, setCommentaires] = useState([]);
  const [addCommentaire, setAddCommentaire] = useState("");
  const [isEdit, setIsEdit] = useState(null);
  const [editMessage, setEditMessage] = useState("");

  const {t} = useTranslation();
  const requestCommunity = new RequestCommunity();
  const username = auth.getUsername()
  const notificationService = useNotification();

  useEffect(() => {
    getAllComment();
  }, [])

  async function getAllComment() {
    const response = await requestCommunity.getAllComment(stocks);
    response.data.sort((a, b) => new Date(b.id) - new Date(a.id))
    setCommentaires(response.data);
  }

  async function addComment() {
    if (addCommentaire.length > 0) {
      const resp = await requestCommunity.addComment(addCommentaire, stocks);
      setCommentaires([resp.data, ...commentaires]);
      setAddCommentaire("");
    }
  }

  async function likeAction(id) {
    if (auth.isLoggedIn()) {
      const resp = await requestCommunity.likeComment(id, utils.interaction.LIKE);
      const index = commentaires.findIndex(objet => objet.id === id);
      if (index !== -1) {
        const nouveauxObjets = [...commentaires];
        nouveauxObjets[index] = resp.data;
        setCommentaires(nouveauxObjets);
      }
    } else {
      notificationService.notify(t('stock.chat.needLogin'), 'info');
    }
  }

  async function deleteAction(id) {
    await requestCommunity.deleteComment(id);
    setCommentaires(commentaires => commentaires.filter(objet => objet.id !== id));
  }

  async function initEditMessage(id, content) {
    await setIsEdit(id);
    await setEditMessage(content);
  }

  function stopEdit() {
    setIsEdit(null);
    setEditMessage("");
  }

  async function validateEdit() {
    const resp = await requestCommunity.editComment(isEdit, editMessage);
    const index = commentaires.findIndex(objet => objet.id === isEdit);
    if (index !== -1) {
      const nouveauxObjets = [...commentaires];
      nouveauxObjets[index] = resp.data;
      setCommentaires(nouveauxObjets);
      await setIsEdit(null);
      await setEditMessage("");
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  return (
    <div className="w-100">
      <h1>{t('stock.chat.commentCount', {count: commentaires.length})}</h1>

      {auth.isLoggedIn() && (
        <div className="d-flex w-100 align-center">
          <InputLabel label={t('stock.chat.addComment')} type="text" id="comment"
                      value={addCommentaire}
                      onInputChange={setAddCommentaire}/>
          <img className="ml-2" src={send} style={{width: "30px", cursor: "pointer"}} alt={"Send"} onClick={() => {
            addComment()
          }}/>
        </div>
      )}

      <div className="d-flex flex-column w-100">
        <div className="card">
          {
            commentaires.map((item, index) => (
              <div className=" d-flex flex-column w-100 box-border" key={index}>

                {index > 0 && (
                  <Divider variant="middle" className={"divider"}/>
                )}

                <div className="d-flex w-100 justify-between mb-1">
                  <div className="d-flex w-100 justify-between">
                    <div className={"d-flex align-center"}>
                      <Avatar sx={{bgcolor: stringToColor(item.userId)}}>{item.userId[0]}</Avatar>
                      <p className={"Gabarito-Bold ml-10"}>{item.userId}</p>
                    </div>
                    <p className={"date"}>
                      {new Date(item.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                {
                  isEdit && isEdit === item.id ?
                    <div className="d-flex align-center">
                      <InputLabel placeholder={item.content} type="text" id={"edit-" + item.id}
                                  value={editMessage}
                                  onInputChange={setEditMessage}
                                  onEscapePressed={stopEdit}
                      />
                      <Button children={t('stock.chat.save')} styles={"button black ml-1-r"}
                              handleClick={validateEdit}/>
                    </div>
                    :
                    <p className={"ml-2"}>{item.content}</p>
                }
                <div className="d-flex justify-between mt-1">
                  <div className="d-flex align-center">
                    <div className={item.interaction ? "like pointer isLike" : "like pointer"}
                         onClick={() => {
                           likeAction(item.id)
                         }}>
                      <img style={{width: 20}} src={like} alt={"Like"}/>
                    </div>
                    <p className="ml-1-r">{item.nbInteraction}</p>
                  </div>
                  <div>
                    {
                      username === item.userId ?
                        <div className="d-flex">
                          <div className="like pointer mr-1-r" onClick={() => {
                            initEditMessage(item.id, item.content)
                          }}>
                            <img style={{width: 20}} src={edit} alt={"Edit"}/>
                          </div>
                          <div className="like pointer" onClick={() => {
                            deleteAction(item.id)
                          }}>
                            <img style={{width: 20}} src={bean} alt={"Delete"}/>
                          </div>
                        </div>
                        :
                        <></>
                    }
                  </div>

                </div>

              </div>
            ))
          }
          {commentaires.length === 0 && (
            <p>{t('stock.chat.noComment')}</p>
          )}

        </div>

      </div>
    </div>
  );
};

export default StocksChat;