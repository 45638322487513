import './App.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AuthPage from './pages/AuthPage/AuthPage';
import NotFound from './pages/NotFound/NotFound'
import Header from './containers/Header/Header';
import routes from './utils/routes.json'
import Market from './pages/Market/Market';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import About from './pages/About/About';
import Stock from './pages/Stock/Stock';
import Wallet from "./pages/HomePage/Wallet/Wallet";
import Legal from './pages/Legal/Legal';
import Account from "./pages/Account/Account";
import {NotificationProvider} from "./containers/NotificationContext/NotificationContext";
import EmailAction from "./pages/utilPages/verifyEmail/EmailAction";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";


function App() {

    return (
        <div className="App">
          <NotificationProvider>
            <Router>
                <Header/>
                <Routes>
                    <Route path={routes.home} element={<HomePage/>}/>
                    <Route path={routes.auth} element={<AuthPage/>}/>
                    <Route path={routes.market} element={<Market/>}/>
                    <Route path={routes.leaderboard} element={<Leaderboard/>}/>
                    <Route path={routes.about} element={<About/>}/>
                    <Route path={routes.stock} element={<Stock/>}/>
                    <Route path={routes.demo} element={<Wallet demo={true}/>}/>
                    <Route path={routes.legal} element={<Legal/>}/>
                    <Route path={routes.account} element={<Account/>}/>
                    <Route path={routes.emailAction} element={<EmailAction/>}/>
                    <Route path={routes.forgotPassword} element={<ForgotPassword/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
          </NotificationProvider>
        </div>
    );
}

export default App;
