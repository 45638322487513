import React, {useEffect, useState} from 'react'
import './Header.scss'
import {useTranslation} from 'react-i18next';
import Button from '../../components/Buttons/Button/Button';
import routes from '../../utils/routes.json'
import NavTo from '../../components/NavTo/NavTo';
import logo from "./../../assets/img/logo.png"
import {useLocation} from "react-router-dom";
import hamburger from "../../assets/img/hamburger.png";
import InputResearch from "../../components/Input/InputResearch/InputResearch";
import AccountMenu from "../AccountMenu/AccountMenu";
import {Auth} from "../../utils/Auth";
import {ClickAwayListener} from "@mui/material";


function Header({}) {
    const location = useLocation();
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const auth = new Auth();
    const isAuth = auth.isLoggedIn();

    useEffect(() => {
        setIsOpen(false)
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === routes.home) {
            const baliseHeader = document.getElementById("header");
            baliseHeader.className = "header h-home"
            if (!isAuth) {
                const barreRecherche = document.getElementById("searchBar");
                barreRecherche.style.display = "none";
            }
        }

        return () => {
            const baliseHeader = document.getElementById("header");
            baliseHeader.className = "header"
            const barreRecherche = document.getElementById("searchBar");
            barreRecherche.style.display = "block";
        };

    }, [location.pathname, isAuth]);


    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div className="d-flex flex-column">
                <div id="header" className='header'>
                    <div className="d-flex align-center ml-1">
                        <NavTo
                            path={routes.home}
                            className='logoApp'>

                            <div
                                className={`bourseplay-header ${(location.pathname === routes.home) && !isAuth ? '' : 'display-none-phone'}`}>
                                {t('home.bourse')}
                            </div>
                            <div
                                className={`bourseplay-header ${(location.pathname === routes.home) && !isAuth ? '' : 'display-none-phone'}`}>
                                {t('home.play')}
                            </div>
                            <div
                                className={(location.pathname === routes.home) && !isAuth ? 'containerLogo display-none-phone' : 'containerLogo'}>
                                <img className='logo' src={logo} alt='logo'/>
                            </div>
                        </NavTo>
                    </div>
                    <div id="content-nav" className="w-100 d-flex">
                        <div className='containerNavBar flex-item-1'>
                            {isAuth && <NavTo
                                path={routes.home}
                            >
                                <div className='itemNav'>
                                    {t('header.wallet')}
                                </div>
                            </NavTo>}
                            <NavTo
                                path={routes.market}
                            >
                                <div className='itemNav'>
                                    {t('header.market')}
                                </div>
                            </NavTo>
                            <NavTo
                                path={routes.leaderboard}>
                                <div className='itemNav'>
                                    {t('header.leaderboard')}
                                </div>
                            </NavTo>
                            <NavTo
                                path={routes.about}>
                                <div className='itemNav'>
                                    {t('header.about')}

                                </div>
                            </NavTo>
                            <div id="searchBar" style={{display: "block"}}>
                                <InputResearch clearOnBlur={true} className={"autocomplete-small"}/>
                            </div>
                        </div>

                        <div className="flex-item-1 grow-inherit" style={isAuth ? {minWidth: '5%'} : {minWidth: '26%'}}>
                            {!isAuth ? <div className='buttonsLogin w-100 d-flex justify-end'>
                                <NavTo className='custom-link'
                                       path={routes.auth}
                                       state={routes.login}
                                >
                                    <Button children={t('header.login')} styles={"button black"}/>
                                </NavTo>
                                <NavTo
                                    className='custom-link'
                                    path={routes.auth}
                                    state={routes.register}
                                >
                                    <Button children={t('header.register')} styles={"button"}/>
                                </NavTo>
                            </div> : <div className="w-100 d-flex justify-center">
                                <AccountMenu/>
                            </div>}

                        </div>
                    </div>

                    <div id="hamburger-nav">
                        <img src={hamburger} className="w-2-r pointer"
                             onClick={(e) => setIsOpen(!isOpen)} alt="Navigation"/>
                    </div>

                </div>

                <div className={`container-nav-resp ${isOpen ? 'open' : ''} `}>
                    {isAuth &&
                        <NavTo path={routes.home}>
                            <div className='itemNav'>
                                {t('header.wallet')}
                            </div>
                        </NavTo>
                    }

                    <NavTo path={routes.market}>
                        <div className='itemNav'>
                            {t('header.market')}
                        </div>
                    </NavTo>

                    <NavTo path={routes.leaderboard}>
                        <div className='itemNav'>
                            {t('header.leaderboard')}
                        </div>
                    </NavTo>

                    <NavTo path={routes.about}>
                        <div className='itemNav'>
                            {t('header.about')}
                        </div>
                    </NavTo>

                    {!isAuth ?
                        <div className='navResponsive d-flex flex-column w-100 align-center'>
                            <NavTo className='custom-link'
                                   path={routes.auth}
                                   state={routes.login}
                            >
                                <Button children={t('header.login')} styles={"button black"}/>
                            </NavTo>
                            <NavTo
                                className='custom-link'
                                path={routes.auth}
                                state={routes.register}
                            >
                                <Button children={t('header.register')} styles={"button"}/>
                            </NavTo>
                        </div> :
                        <AccountMenu/>
                    }
                </div>

            </div>
        </ClickAwayListener>
    )
}

export default Header;
