import React, {useState} from "react";
import {Avatar, IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import {Auth} from "../../utils/Auth";
import Logout from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import "./AccountMenu.scss"
import NavTo from "../../components/NavTo/NavTo";
import routes from "../../utils/routes.json";
import {useTranslation} from "react-i18next";

function AccountMenu({className}) {
    const auth = new Auth();
    const username = auth.getUsername()

    const [anchorEl, setAnchorEl] = useState(null);
    const showMenu = Boolean(anchorEl);

    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`${className}`}>
            <Tooltip title={t("header.account")}>
                <IconButton onClick={handleClick} sx={{p: 0}}>
                    <Avatar>
                        {username && username[0].toUpperCase()}
                    </Avatar>
                </IconButton>
            </Tooltip>

            <Menu open={showMenu}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  onClick={handleClose}
                  disableScrollLock
                  PaperProps={{
                      elevation: 0,
                      sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                          },
                          '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                          },
                      },
                  }}
                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                  anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >

                <NavTo path={routes.account}>
                    <MenuItem>
                        <ListItemIcon>
                            <ManageAccountsIcon/>
                        </ListItemIcon>
                        {t("header.myAccount")}
                    </MenuItem>
                </NavTo>
                <MenuItem onClick={auth.signOut}>
                    <ListItemIcon>
                        <Logout/>
                    </ListItemIcon>
                    {t("header.logout")}
                </MenuItem>
            </Menu>
        </div>
    )
}

export default AccountMenu;