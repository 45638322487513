const constants = {
    // Names
    short_name: "BP",
    name: "BoursePlay",

    // Colors
    theme_color: "#000000",
    background_color: "#ffffff",
    green: "rgb(66,213,31)",
    red: "rgb(255, 99, 132)",

    // URLs
    url_api: process.env.NODE_ENV === 'production' ? "https://bourseplay.com/api" : "http://localhost:8080/api",

    url_community: function () {return this.url_api + "/community"},
    url_api_bourse: function () {return this.url_api + "/bourse"},
    url_api_portefeuille: function () {return this.url_api + "/portefeuille"},
    url_api_automation: function () {return this.url_api + "/automation"},

    // Constants
    starting_money: 10000,

    // Notifications
    default_duration: 5000,
    default_severity: "success",
};

export default constants;