import React, {useEffect, useState} from 'react'
import {Auth} from "../../utils/Auth";
import './Leaderboard.scss';
import {RequestWallet} from "../../request/RequestWallet";
import LeaderboardTable from "../../containers/Table/LeaderboardTable/LeaderboardTable";
import Spinner from "../../components/Spinner/Spinner";
import podium from "../../assets/img/podium2.png"
import {useTranslation} from "react-i18next";
import {round} from "../../utils/services";


function Leaderboard() {
    const {t} = useTranslation();
    const [classement,setClassement] = useState(null)
    const requestWallet = new RequestWallet();
    const [isLoading,setIsLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState('');
    const auth = new Auth();

    useEffect( ()=>{
        getClassement()
    },[])

    async function getClassement(){
        const username = await auth.getUsername();
        const resp = await requestWallet.getClassement(username);
        await setClassement(resp.data)
        setIsLoading(true)
    }

    useEffect(() => {
        function startTimer() {
            let now = new Date();
            let target = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 22, 0, 0, 0);

            if (now.getTime() > target.getTime()) {
                target.setDate(target.getDate() + 1);
            }

            let diff = target - now;
            let hours = Math.floor(diff / 1000 / 60 / 60);
            diff -= hours * 1000 * 60 * 60;
            let minutes = Math.floor(diff / 1000 / 60);
            diff -= minutes * 1000 * 60;
            let seconds = Math.floor(diff / 1000);
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            setTimeRemaining(`${hours}:${minutes}:${seconds}`);
            setTimeout(startTimer, 1000);
        }

        startTimer();
    }, []);

    return (
        <div className="containerPage">
            <div className="card">
                <h1 className='mb-0'>{t('leaderboard.general')}</h1>
                <p><span className='font-bold'>{t('leaderboard.timeBeforeUpdate')}</span> {timeRemaining}</p>
                {
                    isLoading ?
                        <>
                            <div className="d-flex justify-center relative d-sm-none">
                                {classement.leaderboard[0] &&
                                    <div className={`podium n1 ${auth.getUsername() === classement.leaderboard[0].username ? 'font-bold' : ''}`}>
                                        <p>{classement.leaderboard[0].username}</p>
                                        <p>{round(classement.leaderboard[0].totalValue, 2) + ' $'}</p>
                                    </div>
                                }
                                {classement.leaderboard[1] &&
                                    <div className={`podium n2 ${auth.getUsername() === classement.leaderboard[1].username ? 'font-bold' : ''}`}>
                                        <p>{classement.leaderboard[1].username}</p>
                                        <p>{round(classement.leaderboard[1].totalValue, 2) + ' $'}</p></div>
                                }
                                {classement.leaderboard[2] &&
                                    <div className={`podium n3 ${auth.getUsername() === classement.leaderboard[2].username ? 'font-bold' : ''}`}>
                                        <p>{classement.leaderboard[2].username}</p>
                                        <p>{round(classement.leaderboard[2].totalValue, 2) + ' $'}</p>
                                    </div>
                                }
                                <img src={podium} className="w-60-p" alt={"podium"}/>

                            </div>
                            {
                                classement.leaderboard.length>0 && <LeaderboardTable data={classement}/>
                            }
                        </>
                        :
                        <div className="mt-10"><Spinner/></div>
                }
            </div>
        </div>

    )
}

export default Leaderboard