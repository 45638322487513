import React from 'react'
import arezki from '../../assets/img/arezki.png'
import vincent from '../../assets/img/vincent.png'
import alex from '../../assets/img/alex.png'
import damien from '../../assets/img/damien.png'
import logoReact from "../../assets/img/logoReact.png"
import logoNet from "../../assets/img/logoNet.png"
import logoDocker from "../../assets/img/logoDocker.png"
import logoSpring from "../../assets/img/logoSpring.png"
import logoIn from "../../assets/img/in.png"
import logoGH from "../../assets/img/github.png"

import "./About.scss"
import {useTranslation} from "react-i18next";


function About() {

    const {t} = useTranslation();

    return (
        <div className="containerPage d-flex">
            <div className="pageAbout w-100 text-center">
                <div className="w-100 simpletype">BOURSEPLAY</div>

                <div className="mt-5">
                    <h1>{t('about.ourMission')}</h1>
                    <div className="mt-3 mb-1 d-flex justify-center text-justify">
                        <p className={"paragraph"}>
                            {t('about.paragraphTeam')}
                        </p>
                    </div>
                    <div className="mt-1 mb-3 d-flex justify-center text-justify">
                        <p className={"paragraph"}>
                            {t('about.paragraphMission')}
                        </p>
                    </div>
                    <div className="d-flex w-80 ml-10 overflow-hidden">
                        <div className="scroller">
                            {[...Array(5)].map((_, i) => ( // Duplication pour un défilement continu
                                <React.Fragment key={i}>
                                    <div className="d-flex flex-column align-center">
                                        <img style={{ width: "100px" }} src={logoReact} alt="React" className="pointer" />
                                    </div>
                                    <div className="d-flex flex-column align-center">
                                        <img style={{ width: "100px" }} src={logoSpring} alt="Spring" className="pointer" />
                                    </div>
                                    <div className="d-flex flex-column align-center">
                                        <img style={{ width: "100px" }} src={logoNet} alt=".NET" className="pointer" />
                                    </div>
                                    <div className="d-flex flex-column align-center">
                                        <img style={{ width: "100px" }} src={logoDocker} alt="Docker" className="pointer" />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <h1>{t('about.boursePlay')}</h1>
                    <div className="mt-3 mb-3 d-flex justify-center text-justify">
                        <p className={"paragraph"}>
                            {t('about.paragraphBoursePlay')}
                        </p>
                    </div>
                </div>
                <div className="team">
                    <h1>{t('about.team')}</h1>
                    <div className="d-flex w-100 justify-evenly flex-wrap profil-container">
                        <div className="d-flex flex-column align-center">
                            <img src={alex} alt="DUPRÉ Alexandre"/>
                            <h2>DUPRÉ Alexandre</h2>
                            <div className="d-flex justify-center socials">
                                <a href="https://www.linkedin.com/in/alexandre-dupr%C3%A9-/" target="_blank">
                                    <img src={logoIn} alt="LinkedIn"/>
                                </a>
                                <a href="https://github.com/alexandre-dpr" target="_blank">
                                    <img className="ml-2" src={logoGH} alt="GitHub" />
                                </a>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-center">
                            <img src={arezki} alt="AID Arezki"/>
                            <h2>AID Arezki</h2>
                            <div className="d-flex justify-center socials">
                                <a href="https://www.linkedin.com/in/arezki-aid-565a491b5/" target="_blank">
                                    <img src={logoIn} alt="LinkedIn"/>
                                </a>
                                <a href="https://github.com/Arezkii" target="_blank">
                                    <img className="ml-2" src={logoGH} alt="GitHub" />
                                </a>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-center">
                            <img src={damien} alt="CRESPEAU Damien"/>
                            <h2>CRESPEAU Damien</h2>
                            <div className="d-flex justify-center socials">
                                <a href="https://www.linkedin.com/in/damien-crespeau-5837a421b/" target="_blank">
                                    <img src={logoIn} alt="LinkedIn"/>
                                </a>
                                <a href="https://github.com/DamienCrsp" target="_blank">
                                    <img className="ml-2" src={logoGH} alt="GitHub" />
                                </a>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-center">
                            <img src={vincent} alt="DEBERNARDI Vincent"/>
                            <h2>DEBERNARDI Vincent</h2>
                            <div className="d-flex justify-center socials">
                                <a href="https://www.linkedin.com/in/debernardi-vincent/" target="_blank">
                                    <img src={logoIn} alt="LinkedIn"/>
                                </a>
                                <a href="https://github.com/VincentDbnd" target="_blank">
                                    <img className="ml-2" src={logoGH} alt="GitHub" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About