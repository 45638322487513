import constants from "../utils/constants";
import Interceptor from "./Interceptor";
import endpoints from "../utils/endpoints.json";

export class RequestWallet {
    axios = Interceptor.getInstance();
    api_url = constants.url_api_portefeuille();

    async getWallet() {
        try {
            return await this.axios.get(this.api_url);
        } catch (error) {
            console.error(error);
        }
    }

    async deleteWallet() {
        try {
            return await this.axios.delete(this.api_url);
        } catch (error) {
            console.error(error);
        }
    }

    async getHistorique() {
        try {
            return await this.axios.get(`${this.api_url}${endpoints.historique}`);
        } catch (error) {
            throw error;
        }
    }

    async acheter(ticker, quantity) {
        const qtt = parseInt(quantity)
        try {
            return await this.axios.post(`${this.api_url}${endpoints.achat}`, {
                ticker: ticker,
                quantity: qtt
            });
        } catch (error) {
            throw error;
        }
    }

    async vendre(ticker, quantity) {
        const qtt = parseInt(quantity)
        try {
            return await this.axios.post(`${this.api_url}${endpoints.vente}`, {
                ticker: ticker,
                quantity: qtt
            });
        } catch (error) {
            throw error;
        }
    }

    async getClassement(username){
        try {
            return await this.axios.get(`${this.api_url}${endpoints.leaderboard}?username=${username}`);
        } catch (error) {
            throw error;
        }
    }

    async getStockPerformance(ticker){
        try {
            return await this.axios.get(`${this.api_url}${endpoints.stock}/${ticker}`);
        } catch (error) {
            if(error.response.status === 404){
                return 0;
            }else{
                console.error(error);
            }
        }
    }

    async addFavori(ticker){
        try{
            return await this.axios.post(`${this.api_url}${endpoints.favori}/${ticker}`);
        }catch (error){
            console.error(error)
        }
    }

    async delFavori(ticker){
        try{
            return await this.axios.delete(`${this.api_url}${endpoints.favori}/${ticker}`);
        }catch (error){
            console.error(error)
        }
    }

}