import React, {useEffect, useState} from 'react'
import './Stock.scss'
import {useLocation, useParams} from 'react-router-dom';
import StocksChat from "../../containers/StocksChat/StocksChat";
import {Auth} from "../../utils/Auth";
import {getStock} from "../../request/RequestMarket";
import utils from "../../utils/utils.json"
import CustomImage from "../../components/CustomImage/CustomImage";
import {formatCurrency, getStockLogo, percentageDiff, timestampToDDMMYY, timestampToHHMM} from "../../utils/services";
import {useTranslation} from 'react-i18next';
import logoCeo from "../../assets/img/user.png"
import logoWeb from "../../assets/img/web.png"
import logoExchange from "../../assets/img/exchange.png"
import LineChart from "../../components/Charts/LineChart/LineChart";
import TransactionWidget from "../../containers/TransactionWidget/TransactionWidget";
import Spinner from "../../components/Spinner/Spinner";
import constants from "../../utils/constants.js"
import {RequestWallet} from "../../request/RequestWallet";
import star_fill from "../../assets/img/star_fill.png";
import star_empty from "../../assets/img/star_empty.png";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Divider} from "@mui/material";

function Stock() {
    const requestWallet = new RequestWallet();
    const {t} = useTranslation();
    const {ticker} = useParams();
    const [username, setUsername] = useState(null);
    const [data, setData] = useState(null);
    const ONE_DAY = utils.rangeHistoryStock.ONE_DAY
    const ONE_YEAR = utils.rangeHistoryStock.ONE_YEAR
    const [range, setRange] = useState(ONE_YEAR[0]);
    const [history, setHistory] = useState([])
    const [timeStamp, setTimeStamp] = useState([])
    const [performance, setPerformance] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [isFav, setFav] = useState(false)
    const auth = new Auth();
    const location = useLocation();

    async function fetchData() {
        if (auth.isLoggedIn()) {
            const result_wallet = await requestWallet.getWallet();
            if (result_wallet.data && result_wallet.data.favoris.length > 0) {
                const favsTickers = result_wallet.data.favoris.map(stock => stock.ticker)
                setFav(favsTickers.includes(ticker))
            } else {
                setFav(false)
            }
        }

        const result = await getStock(ticker, range);
        setData(result.data)
        if (result.data) {
            if (result.data.history.chart.result[0].timestamp !== undefined && result.data.history.chart.result[0].indicators.quote[0].high !== undefined) {
                if (range === ONE_DAY[0]) {
                    setTimeStamp(timestampToHHMM(result.data.history.chart.result[0].timestamp))
                } else {
                    setTimeStamp(timestampToDDMMYY(result.data.history.chart.result[0].timestamp))
                }
                setHistory(result.data.history.chart.result[0].indicators.quote[0].high)
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true)

        async function fetchUsername() {
            const auth = new Auth();
            const name = await auth.getUsername();
            await setUsername(name);
        }

        fetchUsername();
        fetchData()
    }, [range, location]);


    useEffect(() => {
        setPerformance(percentageDiff(history[0], history[history.length - 1]))
    }, [history, range, location]);


    const handleChangeRange = (newRange) => {
        setRange(newRange)
    }

    async function manageFav() {
        if (isFav) {
            await requestWallet.delFavori(ticker)
        } else {
            await requestWallet.addFavori(ticker)
        }
        fetchData()
    }


    return (
        <div className='containerPage'>
            {
                isLoading ?
                    <div className="mt-10">
                        <Spinner/>
                    </div>
                    :
                    <div className={"pageStock"}>
                        <div className="d-flex w-100 containerSections">
                            <div className={"containerStockGraph "}>
                                <div className="leftSide w-100">
                                    <div className={"d-flex align-center stock-header"}>
                                        <CustomImage src={getStockLogo(ticker)} alt={"Stock logo"} className={"stock-image"}/>
                                        <div className="d-flex align-end w-100">
                                            <h1 className="name">{data?.name} </h1>
                                            <h1 className="ml-1 ticker"> {ticker}</h1>
                                        </div>
                                        {auth.isLoggedIn() &&
                                            <div>
                                                <img onClick={manageFav} src={isFav ? star_fill : star_empty}
                                                      alt={"favorite"}
                                                      className={"favorite mt-1"}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="d-flex w-100">
                                        <div className="lineChart w-100">
                                            <div className="d-flex justify-between align-center headerLineChart">
                                                <div className="d-flex align-center w-100">
                                                    <h1> {`${data?.price} ${data?.currency}`}</h1>
                                                    <h3 className="ml-3"> {performance[1]}</h3>
                                                </div>
                                                <div className="justify-end d-flex w-50">
                                                    <div onClick={() => handleChangeRange(ONE_DAY[0])}
                                                         className={range === ONE_DAY[0] ? "focus boxRange mr-2" : "boxRange mr-2"}> {ONE_DAY[1]} </div>
                                                    <div onClick={() => handleChangeRange(ONE_YEAR[0])}
                                                         className={range === ONE_YEAR[0] ? "focus boxRange" : "" + ` boxRange`}> {ONE_YEAR[1]} </div>
                                                </div>
                                            </div>
                                            {<div className="mb-5 containerLineChart w-100">
                                                <LineChart
                                                    className={"stock-graph"}
                                                    data={history}
                                                    labels={timeStamp}
                                                    lineColor={performance[0] > 0 ? constants.green : constants.red}
                                                />
                                            </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={"w-100 spacer-mobile"}>
                                <Divider/>
                            </div>

                            <div className="scrollbar-none transaction-widget">
                                <TransactionWidget price={data?.price} ticker={ticker}/>
                            </div>
                        </div>

                        <div className={"stock-info mb-2"}>
                            <Divider/>
                            <div className="statistics">
                                <h2 className={"mb-1"}> {t('stock.statistics')}</h2>
                                <div className="d-flex w-100">
                                    <div className="info-container">
                                        <p className="label">
                                            {t('stock.marketCap')}
                                        </p>
                                        <p className="item">
                                            {`${formatCurrency(data?.marketCap)} ${data?.currency}`}
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="about mt-3">
                                <h2 className={"mt-4"}>{`${t('stock.about')} ${data?.name}`}</h2>
                                <div>
                                    <div className="infos mt-1 mb-2 w-100 d-flex flex-column">

                                        {data?.ceo !== null && data?.ceo !== "" &&
                                            <div className="ceo d-flex align-center">
                                                <img src={logoCeo} style={{width: "30px"}} alt={"Ceo"}/>
                                                <div className="info-container">
                                                    <p className="label ml-2">
                                                        {t('stock.ceo')}
                                                    </p>
                                                    <p className={'item'}>
                                                        {data?.ceo}
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                        {data?.website !== null && data?.website !== "" &&
                                            <div className="website d-flex  align-center">
                                                <img src={logoWeb} style={{width: "30px"}} alt={"Website"}/>
                                                <div className="info-container">
                                                    <p className="label ml-2">{t('stock.website')}</p>
                                                    <a href={data?.website} target="_blank" rel="noopener noreferrer"
                                                       className="item">
                                                        {data?.website}
                                                    </a>
                                                </div>

                                            </div>
                                        }

                                        {data?.exchange !== null && data?.exchange !== "" &&
                                            <div className="exchange d-flex align-center">
                                                <img src={logoExchange} style={{width: "30px"}} alt={"Exchange"}/>
                                                <div className="info-container">
                                                    <p className="label ml-2">
                                                        {t('stock.exchange')}
                                                    </p>
                                                    <p className="item">
                                                        {data?.exchange}
                                                    </p>
                                                </div>

                                            </div>
                                        }

                                        <div>
                                            <div className="exchange d-flex align-center">
                                                <LocationOnIcon style={{ width: "30px", height: "30px" }}/>
                                                <div className="info-container">
                                                    <p className="label ml-2">
                                                        {t('stock.ticker')}
                                                    </p>
                                                    <p className="item">
                                                        {data?.ticker}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {data?.description !== null && data?.description !== "" &&
                                        <div className="stockDescription w-100">
                                            {data?.description}
                                        </div>}
                                </div>

                            </div>
                        </div>

                        <Divider/>

                        <StocksChat stocks={ticker}/>
                    </div>

            }
        </div>)
}

export default Stock