import React, {useEffect} from "react";
import {Chart} from "react-chartjs-2";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import bg_pc from "../../../assets/img/bg-pc-resize-tiny.png";
import Button from "../../../components/Buttons/Button/Button";
import AnimatedLineChart from "../../../components/Charts/AnimatedLineChart";
import NavTo from "../../../components/NavTo/NavTo";
import styles from "../../../index.scss";
import routes from "../../../utils/routes.json";
import "./PresentationPage.scss";
import playIcon from "../../../assets/img/play.png";

function PresentationPage() {
  const location = useLocation();

  useEffect(() => {
    const isReloaded = localStorage.getItem("isReloaded");
    if (!isReloaded) {
      const baliseFooter = document.getElementById("homePageFooter");
      baliseFooter.classList.add("initial-load-animation");
      localStorage.setItem("isReloaded", true);
    }

    return () => {
      localStorage.removeItem("isReloaded");
    };
  }, [location.pathname]);

  useEffect(() => {
    document.body.style.backgroundColor = styles.primaryColor;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const { t } = useTranslation();

  const data = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(132,104,239)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const data2 = {
    labels: ["Red", "Blue", "Purple"],
    datasets: [
      {
        data: [30, 140, 70],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(132,104,239)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="homePage">
      <div className="paper paper-1">
        <AnimatedLineChart />
      </div>

      <div className="paper paper-2">
        <Chart
          options={options}
          className={"doughnutChart"}
          type={"doughnut"}
          data={data}
        />
        <Chart
          options={options}
          className={"doughnutChart"}
          type={"doughnut"}
          data={data2}
        />
      </div>

      <div className="w-100 simpletype text-center title-long">BOURSEPLAY</div>
      <div className="w-100 simpletype text-center title-letter">B</div>

      <svg
        fill="none"
        className="bg-svg"
      >
        <path
          d="M0 -217L2160.5 1200L2303.5 -158.5L0 -217Z"
          fill="url(#paint0_linear)"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="611.5"
            y1="564"
            x2="2230.5"
            y2="276.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#36A2EB" stop-opacity="0.15"></stop>
            <stop offset="2" stop-color="#FF6384" stop-opacity="0.35"></stop>
          </linearGradient>
        </defs>
      </svg>

      <div id="bg-pc" className="bg-pc">
        <img loading="lazy" src={bg_pc} alt={"tablet"} />
      </div>

      <div id="homePageFooter" className="homePageFooter">
        <div className="slogan">
          {t("home.slogan")}
          <div className="d-inline-block ml-3 vertical-center buttonDemo">
            <NavTo
              className='custom-link'
              path={routes.demo}
            >
              <Button styles={'button black my-0 pl-3 pr-3'}>
                <div className="d-flex align-center justify-center">
                  {t('home.demo')}
                  <img className="ml-4 demoIcon" src={playIcon} alt="playIcon" />
                </div>
              </Button>
            </NavTo>
          </div>
        </div>
        <div className="phraseAccroche">{t("home.trigger")}</div>
      </div>

      <div className="buttonsHomePage">
        <NavTo
          className='custom-link'
          path={routes.demo}
        >
          <Button styles={'button black my-0'}>
            <div className="d-flex align-center justify-center">
              {t('home.demo')}
              <img className="ml-4 demoIcon" src={playIcon} alt="playIcon" />
            </div>
          </Button>
        </NavTo>
        <NavTo className="custom-link" path={routes.auth} state={routes.login}>
          <Button children={t("header.login")} styles={"button black"} />
        </NavTo>
        <NavTo
          className="custom-link"
          path={routes.auth}
          state={routes.register}
        >
          <Button children={t("header.register")} styles={"button black"} />
        </NavTo>
      </div>
    </div>
  );
}

export default PresentationPage;
