import {jwtDecode} from "jwt-decode";


/**
 * @typedef {Object} Token
 * @property {string} sub - Subject
 * @property {number} exp - Expiration
 * @property {number} iat - Issued at
 * @property {string[]} roles - Roles
 */

export class Auth {
  getEmail() {
    const decodedToken = this.decodeToken();
    if (decodedToken) {
      return decodedToken.email;
    }
    return undefined
  }

  getJwtToken() {
    try {
      return localStorage.getItem("jwt");
    } catch (error) {
      console.error("Error while retrieving JWT token:", error);
      return null;
    }
  }

  setJwtToken(token) {
    try {
      localStorage.setItem("jwt", token);
    } catch (error) {
      console.error("Error while saving JWT token:", error);
    }
  }

  isValidToken() {
    const isValid = jwtDecode(this.getJwtToken()).exp * 1000 > Date.now()
    if (isValid) {
      return true
    } else {
      localStorage.removeItem("jwt")
      return false
    }
  }

  isLoggedIn() {
    return !!this.getJwtToken() && this.isValidToken()
  }


  signOut() {
    try {
      localStorage.removeItem("jwt")
      window.location.replace("/")
    } catch (error) {
      console.error("Error clearing JWT token:", error);
    }
  }

  decodeToken() {
    const token = this.getJwtToken();
    if (token) {
      return jwtDecode(token);
    } else {
      return null;
    }
  }

  getUsername() {
    if (this.isLoggedIn()) {
      const decodedToken = this.decodeToken();
      if (decodedToken) {
        return decodedToken.sub;
      }
    }
    return undefined;
  }


}