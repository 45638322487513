import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import {useNotification} from "../../../containers/NotificationContext/NotificationContext";
import {RequestAuth} from "../../../request/RequestAuth";
import {useTranslation} from "react-i18next";

function PasswordChange({onSubmit, className}) {

    const {t} = useTranslation();
    const notificationService = useNotification();
    const requestAuth = new RequestAuth();

    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [error, setError] = useState(undefined);
    const [isFormValid, setIsFormValid] = useState(false);

    function isError(password, verifyPassword) {
        if (password === "" || verifyPassword === "") {
            setError(undefined);
            setIsFormValid(false);

        } else if (password !== verifyPassword) {
            setError(t('account.error.differentPasswords'));
            setIsFormValid(false);

        } else if (password.length < 8) {
            setError(t('account.error.passwordTooShort'));
            setIsFormValid(false);

        } else if (password.length > 128) {
            setError(t('account.error.passwordTooLong'));
            setIsFormValid(false);

        } else {
            setError(undefined);
            setIsFormValid(true);
        }
    }

    function changePassword(password) {
        requestAuth.changePassword(password).then(_ => {
            setPassword("")
            setVerifyPassword("")
            notificationService.notify(t('account.success.passwordChanged'), "success")
        })
    }

    function executePasswordChangeFunction() {
        onSubmit ? onSubmit(password) : changePassword(password)
    }

    return <div className={className}>
        <div>
            <h3>{t('account.error.info.newPassword')}</h3>
            <TextField value={password}
                       onChange={event => {
                           setPassword(event.target.value);
                           isError(event.target.value, verifyPassword)
                       }}
                       variant="outlined"
                       fullWidth
                       type="password"
            />
        </div>

        <div className="mb-2">
            <h3>{t('account.error.info.confirmPassword')}</h3>
            <TextField value={verifyPassword}
                       onChange={event => {
                           setVerifyPassword(event.target.value);
                           isError(password, event.target.value)
                       }}
                       variant="outlined"
                       fullWidth
                       type="password"
                       error={error !== undefined}
                       helperText={error}
                       onKeyUp={e => e.key === 'Enter' && isFormValid && executePasswordChangeFunction()}
            />
        </div>

        <Button sx={{
            backgroundColor: "black",
            '&:hover': {
                backgroundColor: "black"
            },
            width: "150px"
        }}
                variant="contained"
                onClick={executePasswordChangeFunction}
                disabled={!isFormValid}
        >
            {t('account.error.info.modify')}
        </Button>
    </div>;
}

export default PasswordChange;