import React, {useEffect, useState} from 'react'
import './InputLabel.scss'

function InputLabel({ onInputChange, label, id, type, placeholder, onEnterPressed, value, onEscapePressed}) {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (onInputChange) {
            onInputChange(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (onEnterPressed) {
                onEnterPressed();
            }
        } else if (event.key === 'Escape') {
            if (onEscapePressed) {
                onEscapePressed();
            }
        }
    };

    return (
        <div className="input-container">
            <input type={type} id={id} placeholder={placeholder} value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown}/>
            <label htmlFor="email">{label}</label>
        </div>
    )
}

export default InputLabel