import React, {useRef, useState} from 'react';
import "./InputResearch.scss"
import iconLoupe from "../../../assets/img/icons8-loupe-50.png"
import {useTranslation} from "react-i18next";
import {Autocomplete, TextField} from "@mui/material";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {findTickerByName} from "../../../request/RequestMarket";
import {getStockLogo} from "../../../utils/services";
import CustomImage from "../../CustomImage/CustomImage";
import routes from "../../../utils/routes.json";
import {useNavigate} from "react-router-dom";

const InputResearch = ({handleChange, handleSubmit, clearOnBlur, className}) => {

  const {t} = useTranslation();
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  function defaultHandleChange(value) {
    if (value && value.length > 1) {
      findTickerByName(value, 1).then(data => {
        setOptions(data.data.content)
      })
    } else {
      setOptions([])
    }
  }

  function defaultHandleSubmit(event, value) {
      if (value) {
        if (event.type !== "click") {
          if (options.length > 0) {
            value = options[0]
          } else {
            if (inputRef.current) {
              inputRef.current.blur();
            }
            return;
          }
        }
          navigate(`${routes.stock_nav}/${value.ticker}`);
          setValue(null);
          setTimeout(() => {
              setOptions([]);
          }, 200);
          if (inputRef.current) {
              inputRef.current.blur();
          }
      }
  }

  return (
    <Autocomplete
      value={value}
      onInputChange={(event, value) => {
        (handleChange || defaultHandleChange)(value);
      }}
      onChange={(event, value) => {
        (handleSubmit || defaultHandleSubmit)(event, value);
      }}
      className={`autocomplete ${className}`}
      renderInput={(params) =>
        <>
        <TextField {...params}
                   label={t('market.research')}
                   InputProps={{
                     ...params.InputProps,
                     endAdornment: null,
                   }}
                   size={"small"}
                   inputRef={inputRef}
        />
        <img className={"iconLoupe"} src={iconLoupe} alt={"search icon"}/>
        </>
      }
      options={options}
      getOptionLabel={(option) => option && option.name ? option.name : ""}
      selectOnFocus
      clearOnBlur={clearOnBlur}
      freeSolo
      clearOnEscape
      renderOption={(props, option, {inputValue}) => {
        const key = option.ticker
        const optionProps = props
        optionProps.key = key
        const matches = match(option.name, inputValue, {insideWords: true});
        const parts = parse(option.name, matches);

        return (
          <li key={key} {...optionProps}>
            <div className={"mr-2 pt-1"}>
              <CustomImage src={getStockLogo(key)} alt={"Stock logo"} style={{width: "20px", height: "20px"}}/>
            </div>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                {part.text}
                </span>
              ))}
              <span style={{color: "#b4b4b4"}}> {key}</span>
            </div>
          </li>
        );
      }}
    />
  );
};

export default InputResearch;