import React from 'react';

import Wallet from './Wallet/Wallet';
import PresentationPage from './PresentationPage/PresentationPage';
import {Auth} from "../../utils/Auth";

function HomePage({}) {
    const auth = new Auth();
    const isAuth = auth.isLoggedIn();

    return <>{isAuth === true ? <Wallet/> : <PresentationPage/>}</>;
}

export default HomePage;
