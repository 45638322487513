import constants from "../utils/constants.js";
import Interceptor from "./Interceptor";

export class RequestCommunity {
    static COMMENT = "/comment";
    static INTERACTION = "/interaction";

    axios = Interceptor.getInstance();
    api_url = constants.url_community()

    async getAllComment(tickers) {
        try {
            return await this.axios.get(this.api_url  + "/"+tickers);
        } catch (error) {
            throw error;
        }
    }

    async addComment(comment,tickers) {
        try {
            return await this.axios.post(this.api_url + RequestCommunity.COMMENT,
                {
                    "content": comment,
                    "ticker": tickers
                });
        } catch (error) {
            throw error;
        }
    }

    async likeComment(idComment, interaction){
        try {
            return await this.axios.post(this.api_url + RequestCommunity.INTERACTION,{
                "idComment":idComment,
                "interaction":interaction
            });
        } catch (error) {
            throw error;
        }
    }

    async deleteComment(idComment){
        try {
            return await this.axios.delete(this.api_url + RequestCommunity.COMMENT + "/" +idComment);
        } catch (error) {
            throw error;
        }
    }

    async editComment(idComment,content){
        try {
            return await this.axios.patch(this.api_url + RequestCommunity.COMMENT + "/" +idComment,{
                "content": content
            });
        } catch (error) {
            throw error;
        }
    }


}