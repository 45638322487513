import React, {useEffect, useState} from 'react'
import './Market.scss'
import {useTranslation} from 'react-i18next';
import utils from "../../utils/utils.json"
import "../../request/RequestMarket";
import MarketTable from "../../containers/Table/MarketTable/MarketTable";
import {findTickerByName, getStocksList, getTrends} from "../../request/RequestMarket";
import {getFormatStocks} from "../../utils/services";
import routes from "../../utils/routes.json";
import {useNavigate} from "react-router-dom";
import InputResearch from "../../components/Input/InputResearch/InputResearch";
import TrendsTable from "../../containers/Table/TrendsTable/TrendsTable";
import Spinner from "../../components/Spinner/Spinner";
import constants from "../../utils/constants.js"
import TablePagination from "@mui/material/TablePagination";


function Market() {
    const {t} = useTranslation();
    const market_cols = utils.market_table
    const labelsToExclude = ["variation", "name", "changeAmount", "currency", "volume", "changePercentage"];
    const trends_cols = utils.gainers.filter(gainer => !labelsToExclude.includes(gainer.label));
    const [gainers, setGainers] = useState([]);
    const [loosers, setLoosers] = useState([]);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState(undefined)

    useEffect(() => {
        fetchMarket();
        fetchTrends();
    }, []);

    useEffect(() => {
        handleChange(search)
    }, [page]);

    async function fetchMarket() {
        const result = await getStocksList(page + 1);
        if (result.data) {
            setData(await getFormatStocks(result.data.content));
            setTotalCount(result.data.page.totalElements);
        }
    }

    async function fetchTrends() {
        const gainers_result = await getTrends()
        if (gainers_result.data) {
            setGainers(await getFormatStocks(gainers_result.data.gainersJson));
            const loosers_result = await getTrends()
            setLoosers(await getFormatStocks(loosers_result.data.loosersJson));
            setIsLoading(false)
        }
    }

    function handleChange(value) {
        if (value && value.length > 1) {
            setSearch(value)
            findTickerByName(value, page + 1).then(async data => {
                setData(await getFormatStocks(data.data.content))
                setTotalCount(data.data.page.totalElements)
            })
        } else {
            setSearch(undefined)
            fetchMarket()
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function handleSubmit(event, value) {
    }

    const handleClickTicker = (ticker) => {
        navigate(`${routes.stock_nav}/${ticker}`)
    }


    return (
        <div className='containerPage'>
            <div className={"pageMarket"}>
                <div className={"trends"}>
                    <div className={"headerMarket"}>
                        <h1>
                            {t('market.trends')}
                        </h1>
                    </div>

                    <div className="d-flex containerTrends">
                        {
                            isLoading ?
                                <Spinner/>
                                :
                                <div className={"boxTrends"}>
                                    <TrendsTable
                                        colorPrice={constants.green}
                                        keyInter={"market.gainers_table"}
                                        columns={trends_cols}
                                        data={gainers}
                                        sign={utils.plus}
                                        handleClickTicker={handleClickTicker}
                                    />
                                </div>

                        }
                        {
                            isLoading ?
                                <Spinner/>
                                :
                                <div className={"boxTrends"}>
                                    <TrendsTable
                                        colorPrice={constants.red}
                                        keyInter={"market.gainers_table"}
                                        columns={trends_cols}
                                        data={loosers}
                                        sign={utils.minus}
                                        handleClickTicker={handleClickTicker}
                                    />
                                </div>
                        }


                    </div>
                </div>
                <div className={"market"}>
                    <div className={"headerMarket mb-1"}>
                        <h1 className={"mb-0 mr-2"}>
                            {t('market.market')}
                        </h1>
                        <div>
                            <InputResearch handleChange={handleChange} handleSubmit={handleSubmit} clearOnBlur={false}/>
                        </div>
                        <div className={"flex-item-1 pagination-top"}>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={utils.rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </div>
                    </div>
                    {
                        isLoading ?
                            <Spinner/>
                            :
                            <MarketTable
                                handleClickTicker={handleClickTicker}
                                columns={market_cols}
                                keyInter={"market.table"}
                                data={data}
                                totalCount={totalCount}
                                page={page}
                                rowsPerPage={utils.rowsPerPage}
                                pagination={true}
                                handleChangePage={handleChangePage}
                            />
                    }

                </div>
            </div>
        </div>
    )
}

export default Market