import React, {useEffect, useState} from 'react'
import './AuthPage.scss'
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import InputLabel from "../../components/Input/InputLabel/InputLabel";
import Button from "../../components/Buttons/Button/Button";
import route from "../../utils/routes.json";
import routes from "../../utils/routes.json";
import {RequestAuth} from "../../request/RequestAuth";
import {Auth} from "../../utils/Auth";
import {jwtDecode} from "jwt-decode";
import tradeCenter from "../../assets/img/pexels-nadi-lindsay-5320288.jpg"
import {useNotification} from "../../containers/NotificationContext/NotificationContext";
import NavTo from "../../components/NavTo/NavTo";

function AuthPage({}) {
    const router = useNavigate();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const requestAuth = new RequestAuth();
    const [authType, setAuthType] = useState();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const auth = new Auth();
    const [isChecked, setIsChecked] = useState(false)
    const notificationService = useNotification();

    useEffect(() => {
        async function verifierToken() {
            const token = await auth.getJwtToken();
            if (token !== null) {
                const dcode = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000);
                const tokenValide = currentTime < dcode.exp;
                if (tokenValide) {
                    router(routes.home);
                }
            }
        }

        verifierToken();
    }, []);

    useEffect(() => {
        setError("")
    }, [authType]);
    useEffect(() => {
        setAuthType(location.state)
    }, [location.state]);

    async function connected() {
        try {
            const resp = await requestAuth.login(login, password);
            await auth.setJwtToken(resp.data);
            router(routes.home);
        } catch (e) {
            let msgError = ""
            if (e.response.status === 401) {
                msgError = t('errors.invalidLoginForm')
            } else if (e.response.status === 403) {
                msgError = t('verifyEmail.verifyEmailFirst')
            } else {
                msgError = t('errors.errorLogin')
            }
            setError(msgError);
        }
    }

    async function register() {
        try {
            if (password === confirmPassword && (password.length >= 8) && isChecked) {
                await requestAuth.register(username, login, password, i18n.language);
                notificationService.notify(t('verifyEmail.sent'), "success");
                router(routes.home);
            } else {
                password !== confirmPassword ? setError(t('errors.incorrectPwdConfirm')) : !isChecked ? setError(t('errors.legalNoChecked')) : setError(t('errors.pwdSize'))
            }
        } catch (e) {
            let msgError = ""
            if (e.response.status === 409) {
                msgError = t('errors.userAlreadyRegistered')
            } else if (e.response.status === 401) {
                msgError = t('errors.invalidRegistrationForm')
            } else if(e.response.status === 403) {
                notificationService.notify(t('verifyEmail.sent'), "success");
                router(routes.home);
            } else {
                msgError = t('errors.errorRegistering')
            }
            setError(msgError);
        }
    }

    useEffect(() => {
    }, [isChecked])

    return (
        <div className="login-container">
            <div className="leftSideAuth w-40">
                <img src={tradeCenter} className="imageBg" alt="Image2 login" />
            </div>
            <div className="form-container w-60">
                <h2>{authType === route.register ? t('login.registration') : t('login.connection')}</h2>
                <form className="w-100 d-flex flex-column justify-center align-center">
                    {
                        authType === route.register ?
                            <div className="w-50 containerInputAuth">
                                <InputLabel label={t('login.username')} type="text" id="username"
                                    onInputChange={setUsername} />
                            </div>
                            : null
                    }
                    <div className="w-50 containerInputAuth">
                        <InputLabel label={authType === route.register ? t('login.email') : t('login.email-username')}
                            type="text" id="email" onInputChange={setLogin} />
                    </div>
                    <div className="w-50  containerInputAuth">
                        <InputLabel label={t('login.password')} type="password" id="password"
                            onInputChange={setPassword} onEnterPressed={authType === route.login ? connected : null} />
                    </div>
                    {
                        authType === route.register ?
                            <div className='w-50 containerInputAuth'>
                                <InputLabel label={t('login.confirmPassword')} type="password" id="confirm-password"
                                    onInputChange={setConfirmPassword} onEnterPressed={register} />
                            </div>

                            : null
                    }
                    {
                        authType === route.register ?
                            <div className='d-flex w-100 acceptance justify-center '>
                                <input onClick={() => setIsChecked(!isChecked)} type='checkbox' />
                                <div >{t('legal.acceptance.part1')}</div>
                                <div className='legalTerms' onClick={() => router(routes.legal)}>{t('legal.acceptance.part2')}</div>
                            </div> : null
                    }

                    {authType === route.login &&
                        <NavTo path={routes.forgotPassword} className="forgotPassword mb-3">
                            <p className="forgotPassword-text mt-0">{t('login.forgotPassword')}</p>
                        </NavTo>
                    }

                    <div className="error mt- mb-1">{error}</div>
                    <Button children={authType === route.login ? t('login.connect') : t('login.register')}
                        styles={"button black"} handleClick={authType === route.login ? connected : register} />
                    <Button children={<div style={{ fontSize: "0.8em" }}> {authType !== route.login ? t('login.connect') : t('login.register')}</div>}
                        styles={"button mt-3"} handleClick={authType === route.register ? () => setAuthType(route.login) : () => setAuthType(route.register)} />
                </form>
            </div>
        </div>
    )
}

export default AuthPage