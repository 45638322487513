import React, {createContext, useContext, useState} from 'react';
import {Alert, Snackbar} from '@mui/material';
import "./NotificationContext.scss";
import constants from "../../utils/constants";

const NotificationContext = createContext();

export const NotificationProvider = ({children}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(constants.default_severity);
  const [duration, setDuration] = useState(constants.default_duration);

  function notify(message, severity = constants.default_severity, duration = constants.default_duration) {
    setMessage(message);
    setSeverity(severity);
    setDuration(duration);
    setOpen(true);
  }

  function closeNotification(event) {
    if (event === null) {
      setOpen(false);
    }
  }

  return (
    <NotificationContext.Provider value={{notify}}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{marginTop: '6vh'}}
        open={open}
        onClose={closeNotification}
        autoHideDuration={duration}
      >
        <Alert
          severity={severity}
          variant="filled"
          sx={{width: '100%'}}
        >
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);