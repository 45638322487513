import React, {useEffect, useState} from "react";
import "./Account.scss"
import {Button, Dialog, DialogActions, DialogTitle, Divider, TextField} from "@mui/material";
import {Auth} from "../../utils/Auth";
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {RequestWallet} from "../../request/RequestWallet";
import {useNavigate} from "react-router-dom";
import routes from "../../utils/routes.json";
import {RequestAuth} from "../../request/RequestAuth";
import {useTranslation} from "react-i18next";
import {useNotification} from "../../containers/NotificationContext/NotificationContext";
import PasswordChange from "../../components/Forms/PasswordChange/PasswordChange";

function Account({}) {
    const auth = new Auth();
    const username = auth.getUsername()
    const email = auth.getEmail()

    const [showResetDialogue, setShowResetDialogue] = useState(false)
    const [showDeleteDialogue, setShowDeleteDialogue] = useState(false)

    const requestWallet = new RequestWallet()
    const requestAuth = new RequestAuth()
    const router = useNavigate()
    const {t} = useTranslation();
    const notificationService = useNotification();

    useEffect(() => {
        if (!auth.isLoggedIn()) {
            router(routes.auth, {state: routes.login});
        }
    }, []);

    function reinitPortefeuille() {
        requestWallet.deleteWallet().then(_ => {
                setShowResetDialogue(false)
                notificationService.notify(t('account.success.walletReset'), "success")
                router(routes.home)
            }
        )
    }

    function deleteAccount() {
        requestAuth.deleteAccount().then(_ => {
            setShowDeleteDialogue(false)
            notificationService.notify(t('account.success.accountDeleted'), "success")
            auth.signOut()
        })
    }

    return (
        <div className="containerPage">
            <div className="mt-4 ml-5 mr-5">
                <div className="mb-2">
                    <h1>{t('account.error.info.title')}</h1>

                    <div className="d-flex justify-between section">
                        <div className="section-field">
                            <div>
                                <h3>{t('account.error.info.username')}</h3>
                                <TextField value={username}
                                           variant="outlined"
                                           disabled
                                           fullWidth
                                />
                            </div>

                            <div>
                                <h3>{t('account.error.info.email')}</h3>
                                <p className="subPhrase mb-1">
                                    {t('account.error.info.emailSub')}
                                </p>
                                <TextField value={email}
                                           variant="outlined"
                                           disabled
                                           fullWidth
                                />
                            </div>
                        </div>

                        <div className="section-field">
                            <PasswordChange/>
                        </div>
                    </div>

                </div>

                <Divider/>

                <div className="mt-2">
                    <h1>{t('account.error.dangerZone.title')}</h1>

                    <div className="d-flex justify-between section">
                        <div className="section-field">
                            <h3>{t('account.error.dangerZone.resetWallet')}</h3>
                            <p className="subPhrase">
                                {t('account.error.dangerZone.resetWalletSub')}
                            </p>
                            <Button variant="contained"
                                    color="error"
                                    onClick={() => setShowResetDialogue(true)}
                                    startIcon={<RestartAltIcon/>}
                            >
                                {t('account.error.dangerZone.resetBtn')}
                            </Button>
                        </div>

                        <div className="section-field">
                            <h3>{t('account.error.dangerZone.deleteAccount')}</h3>
                            <p className="subPhrase">
                                {t('account.error.dangerZone.deleteAccountSub')}
                            </p>
                            <Button variant="contained"
                                    color="error"
                                    onClick={() => setShowDeleteDialogue(true)}
                                    startIcon={<DeleteIcon/>}
                            >
                                {t('account.error.dangerZone.deleteBtn')}
                            </Button>
                        </div>
                    </div>

                </div>

            </div>

            {/*TODO En faire un service*/}
            <Dialog open={showResetDialogue}>
                <DialogTitle sx={{fontFamily: "Gabarito-Bold"}}>
                    {t('account.error.dangerZone.resetConfirm')}
                </DialogTitle>
                <DialogActions sx={{display: "flex", justifyContent: "space-around", marginBottom: "10px"}}>
                    <Button sx={{
                        backgroundColor: "black",
                        '&:hover': {
                            backgroundColor: "black"
                        },
                        width: "150px"
                    }}
                            variant="contained"
                            onClick={() => setShowResetDialogue(false)}
                            autoFocus
                    >
                        {t('account.error.dangerZone.cancel')}
                    </Button>
                    <Button color="error"
                            variant="contained"
                            onClick={reinitPortefeuille}
                            sx={{width: "150px"}}
                    >
                        {t('account.error.dangerZone.resetBtn')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showDeleteDialogue}>
                <DialogTitle sx={{fontFamily: "Gabarito-Bold"}}>
                    {t('account.error.dangerZone.deleteConfirm')}
                </DialogTitle>
                <DialogActions sx={{display: "flex", justifyContent: "space-around", marginBottom: "10px"}}>
                    <Button sx={{
                        backgroundColor: "black",
                        '&:hover': {
                            backgroundColor: "black"
                        },
                        width: "150px"
                    }}
                            variant="contained"
                            onClick={() => setShowDeleteDialogue(false)}
                            autoFocus
                    >
                        {t('account.error.dangerZone.cancel')}
                    </Button>
                    <Button color="error"
                            variant="contained"
                            onClick={deleteAccount}
                            sx={{width: "150px"}}
                    >
                        {t('account.error.dangerZone.deleteBtn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Account;